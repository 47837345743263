import { styled } from "styled-components";

export const ArtifactRow = styled.div`
  position: absolute;
  width: calc(100% + 60px);
  height: min(40px, 3.6vw);
  bottom: max(-20px, -1.8vw);
`;

export const ArtifactBig = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  right: 0;
  background-color: white;
  transform: skewX(-30deg);
  border-radius: 100px;
`;

export const ArtifactMedium = styled.div`
  position: absolute;
  right: 0;
  height: 80%;
  top: 13%;
  width: 100%;
  background-color: white;
  transform: skewX(-30deg);
  border-radius: 100px;
`;


