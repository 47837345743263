import { keyframes } from "styled-components";
import { styled } from "styled-components";
import phone from "../img/phone.svg";
import bw2 from "../img/bw2.jpg";
import up from "../img/arrow-up.svg";
import t from "../img/telegram.svg";
import w from "../img/whatsapp.svg";
import trans from "../img/trans.png";
import blue1 from "../img/blue1.png";
import blue2 from "../img/blue2.png";
import yt from "../img/youtube.svg";

export const YouTube = styled.div`
  display: flex;
  width: 10%;
  padding-top: 10%;
  background: url(${yt});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

export const KekW = styled.div`
  ::-webkit-scrollbar {
    background-color: #e0efff;
    width: min(14px, 0.7vw);
  }
  ::-webkit-scrollbar-thumb {
    background: #2c6aa8;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #357ec6;
  }
`;

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1;
  /* flex-shrink: 0; */
  height: ${(props) => props.vh}px;
  width: 100vw;
  scroll-behavior: smooth;
  /* background: linear-gradient(
    130deg,
    transparent 0%,
    #7fbfff 0%,
    #7fbfff 100%
  ); */
`;

export const Gradien = styled.div`
  width: 100%;
  height: 30px;
  background: linear-gradient(to left, #bcd9f7 20%, #ffffff 60%);
`;

export const Header = styled.div`
  position: fixed;
  display: flex;
  background-color: aquamarine;
  width: 100%;
  height: 80px;
`;
export const LeftPartHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 50%;
  height: 80px;
`;

export const Logo = styled.div`
  width: 100px;
  height: 80px;
  border-radius: 25px;
  margin-left: 35px;
  background: url(${(props) => props.logo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const RightPartHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  width: 50%;
  height: 80px;
`;

export const PhoneForm = styled.div`
  height: 50px;
  width: 340px;
  margin-top: 20px;
  text-align: center;
  flex-shrink: 0;
`;
export const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  border-radius: 20px;
  height: 40px;
  width: 130px;
  margin-right: 10px;
  margin-top: 10px;
  flex-shrink: 0;
  background-color: #7e7e44;
  &:hover {
    background-color: #587a99;
    scale: 1.1;
    cursor: pointer;
  }
`;

const open = keyframes`
    from {
        height:402px;
    } 
    
    to {
        height:670px;
    }
`;

const close = keyframes`
    from {
        height:670px;
    } 
    
    to {
        height:402px;
    }
`;

export const Block1Back = styled.div`
  /* margin-top: 80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    130deg,
    transparent 0%,
    transparent 30%,
    #0079e8 80%,
    #0079e8 100%
  );
  ${(props) =>
    props.common &&
    `
    background: linear-gradient(
    130deg,
    transparent 0%,
    transparent 10%,
    #419fff 40%,
    #419fff 100%
  );
  `}/* background: url(${bw2}); */
  /* background-size: cover; */
`;

export const Block1 = styled.div`
  display: flex;
  flex-shrink: 0;
  /* background-color: #bcd9f7; */
  justify-content: center;
  width: 2000px;
  max-width: 100%;
  /* height: ${(props) => (props.resize ? "670px" : "402px")}; */
  animation: ${(props) =>
      props.isClicked ? (props.resize ? open : close) : ""}
    1s ease-in;
  /* background: linear-gradient(
    130deg,
    transparent 0%,
    transparent 30%,
    #7fbfff 80%,
    #7fbfff 100%
  ); */
`;
export const TextPart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
`;

export const TextMicroPart = styled.div`
  width: 90%;
  padding: min(20px, 1.5vw);
  font-weight: 600;
  font-size: min(30px, 1.7vw);
`;

export const TitleBox = styled.div`
  position: absolute;
  top: 7%;
  left: 3%;
  width: 120%;
  padding: min(20px, 3vw);
  font-size: min(80px, 4vw);
  /* background-image: linear-gradient(90deg, #111, #464646, #111);
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text; */
  /* color: transparent; */
  /* text-shadow: 0px 0px min(40px, 2vw) #fff; */
  /* animation: shine 2s ease-in-out infinite; */
  /* color: blue; */
`;

export const DescBox = styled.div`
  position: absolute;
  top: min(200px, 10vw);
  text-align: center;
  width: 120%;
  /* font-weight: 600; */
  color: #000000;
  padding: min(20px, 3vw);
  font-size: min(32px, 1.6vw);
  margin-left: 3%;
  text-transform: uppercase;
`;

export const PhotoBlock = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  width: 35%;
  white-space: pre-wrap;
`;

export const Photo = styled.div`
  background: url(${(props) => props.photo});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 133%;
  /* margin-left: 90px; */
`;

export const OpenArrow = styled.div`
  background: url(${(props) => props.arrow});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #bcd9f7;
  height: 40px;
  width: 60px;
`;

export const OpenArrowBlock = styled.div`
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-color: #bcd9f7;
  width: 100%;
  height: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const Block2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 2000px;
  max-width: 70%;
  flex-shrink: 0;
  /* min-height: 100px; */
`;

const open2 = keyframes`
    from {
        height: 385px;
    } to {
        height: 2250px;
    }
`;
const close2 = keyframes`
    from {
        height: 2250px;
    } to {
        height: 385px;
    }
`;

export const Block3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2000px;
  max-width: 100%;
  margin-bottom: min(50px, 2.5vw);
  /* justify-content: center; */
  overflow: hidden;
  animation: ${(props) =>
      props.isClicked2 ? (props.resize2 ? open2 : close2) : ""}
    1s ease-in;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Block4 = styled.div`
  display: flex;
  justify-content: center;
  background-color: #bcd9f7;
  width: 100%;
  min-height: 100px;
`;

export const FooterNew = styled.div`
  display: flex;
  justify-content: center;
  background-color: #bcd9f7;
  width: 100%;
  min-height: 100px;
`;

export const LeftPartFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 50%;
  min-height: 100px;
`;

export const RightPartFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 50%;
  min-height: 100px;
`;

export const Trans = styled.div`
  width: 2000px;
  max-width: 100%;
  /* height: min(300px, 15vw); */
  /* background: linear-gradient(
    90deg,
    #8dc8ff 5%,
    #80c2ff 20%,
    #4299e9 35%,
    #4299e9 55%,
    #80c2ff 80%,
    #8dc8ff 95%
  );
  flex-shrink: 0; */
  background: url(${trans});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
`;

export const MainHeaderCanDo = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  font-weight: bold;
  font-size: min(44px, 2.2vw);
  color: #16518e;
  padding: min(40px, 2vw);
  text-transform: uppercase;
`;

export const BigCanDo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2%;
`;

export const CanDoBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #7cbdff;
  border-radius: min(24px, 1.2vw);
  width: 46%;
  white-space: pre-wrap;
  overflow: hidden;
  flex-shrink: 0;
  transition: scale 0.3s;
  user-select: none;
  box-shadow: 0 1px 1px #515151;
  &:hover {
    scale: 1.03;
  }
`;

export const HeaderCanDo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: min(20px, 1vw);
  font-weight: 600;
  font-size: min(22px, 1.1vw);
  white-space: pre-wrap;
  color: #1b3762;
  flex-shrink: 0;
  text-transform: uppercase;
`;

export const CanDoDesc = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(40px, 2vw);
  height: min(320px, 16vw);
  font-size: min(20px, 1vw);
  text-align: center;
  /* background: linear-gradient(
    135deg,
    rgb(65, 159, 255) 0%,
    white 13%,
    white 87%,
    rgb(65, 159, 255) 100%
  ); */
`;

export const PriceLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  /* background-color: #419fff; */
  width: 90%;
  /* max-width: 90%; */
  /* aspect-ratio: 14 / 1; */
  border-radius: min(20px, 1vw);
  /* border: 1px solid black; */
  margin-top: 1%;
  transition: scale 0.4s;
  &:hover {
    scale: 1.03;
    filter: brightness(1.05);
  }
  &:last-child {
    margin-bottom: max(-6px, -0.35vw);
  }
`;

export const ServicePart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: min(30px, 1.5vw);
  padding-left: min(60px, 3vw);
  font-weight: 600;
  width: 83%;
  height: 70%;
  margin-bottom: 1%;
  transform: skew(-10deg);
  margin-right: -1px;
  background-color: #afd7ff;
  border-top-left-radius: min(14px, 0.7vw);
  border-bottom-left-radius: min(14px, 0.7vw);
`;

export const PricePart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: min(60px, 3vw); */
  font-size: min(50px, 2.5vw);
  line-height: min(100px, 5vw);
  /* flex-shrink: 0; */
  margin-bottom: 1%;
  font-weight: 600;
  width: 17%;
  background-color: #419fff;
  transform: skew(-10deg);
  text-shadow: 0 1px black;
  color: white;
  border-radius: min(14px, 0.7vw);
  /* border-bottom-right-radius: min(14px, 0.7vw); */
  /* border-bottom-left-radius: 22%; */
`;

export const PhoneBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  /* aspect-ratio: 8 / 1; */
`;

export const PhoneNumberFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  /* height: 100%; */
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(4deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const PhoneIcon = styled.div`
  width: 17%;
  padding-top: 17%;
  background: url(${phone});
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: center;
  animation: ${rotate} 0.8s infinite;
  /* filter: invert(30%) sepia(0%) saturate(8%) hue-rotate(134deg) brightness(99%)
    contrast(90%); */
`;

export const PhoneNumber = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 6%;
  align-items: center;
  width: 80%;
  height: 100%;
  font-size: min(40px, 2vw);
  /* font-weight: 600; */
  color: #000000;
  transition: transform 0.3s, color 0.3s;
  &:hover {
    transform: scale(1.1);
    color: black;
  }
`;

export const Enroll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  /* font-weight: 600; */
  text-transform: uppercase;
  font-size: min(30px, 1.5vw);
  color: black;
  background-color: white;
  border-radius: min(20px, 1vw);
  user-select: none;
  padding: min(18px, 0.9vw) 0;
  cursor: pointer;
  /* border: 1px solid #979797; */
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #419fff;
    color: white;
    transform: scale(1.08);
    /* border: 1px solid white; */
  }
`;

export const PriceCardFlex = styled.div`
  width: 100%;
  /* aspect-ratio: 24 / 7; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  gap: 2%;
  pointer-events: none;
  /* scroll-behavior: smooth; */
`;

export const PriceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(47% + 2px);
  /* aspect-ratio: 2 / 1; */
  background-color: #419fff;
  flex-shrink: 0;
  border-radius: min(20px, 1vw);
  box-shadow: 0 1px 1px #515151;
  margin-bottom: 1px;
  &:first-child {
    margin-left: 2%;
  }
  &:last-child {
    margin-right: 2%;
  }
`;

export const PriceTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #253342;
  text-align: center;
  font-weight: 600;
  /* aspect-ratio: 10 / 2; */
  background-color: #afd7ff;
`;

export const PricePriceFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* aspect-ratio: 10 / 3; */
  background-color: white;
`;

export const PricePrice = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: min(60px, 3vw);
`;

export const PriceIcon = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UpButton = styled.div`
  position: fixed;
  bottom: min(26px, 1.3vw);
  right: min(40px, 2vw);
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(64px, 3.2vw);
  height: min(64px, 3.2vw);
  border-radius: min(16px, 0.8vw);
  background-color: #2a78c7;
  opacity: 0.5;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  z-index: 10;
`;

export const UpImage = styled.div`
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1 / 1; */
  background: url(${up});
  background-size: 45%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg)
    brightness(102%) contrast(102%);
`;

export const TWBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: min(20px, 1vw);
`;

export const Telegram = styled.div`
  width: min(80px, 4vw);
  /* aspect-ratio: 1 / 1; */
  background: url(${t});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: scale 0.3s, filter 0.3s;
  cursor: pointer;
  &:hover {
    scale: 1.2;
    filter: brightness(1.1);
  }
`;

export const Whatsapp = styled.div`
  width: min(80px, 4vw);
  aspect-ratio: 1 / 1;
  background: url(${w});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: scale 0.3s, filter 0.3s;
  cursor: pointer;
  &:hover {
    scale: 1.2;
    filter: brightness(1.05);
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 4%;
  position: absolute;
`;

export const FooterRow = styled.div`
  width: 100%;
  margin-top: min(20px, 1vw);
  height: min(60px, 3vw);
  font-size: min(30px, 1.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14283c;
  color: #90c7ff;
  font-weight: 600;
`;

export const FooterText = styled.div`
  padding: 0 min(10px, 0.5vw);
`;

export const Transition = styled.div`
  width: 100%;
  /* height: 400px; */
  padding-top: 50%;
  margin-top: -12%;
  background: url(${blue1});
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
`;
