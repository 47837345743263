import styled from "styled-components";
import arrow from "../../img/arrow-right2.svg";
import arrowSlideIcon from "../../img/arrow-right.svg";

export const SliderGrandBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
`;

export const SliderBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1%;
  width: calc(100% - 40px);
`;

export const SliderCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

export const SLiderCard = styled.div`
  width: 300px;
  height: 440px;
  flex-shrink: 0;
  perspective: 5000px;
  &:hover ${SliderCardInner} {
    transform: rotateY(180deg);
  }
`;

export const SliderBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: aqua;
`;

export const SliderFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #434d4d;
`;

export const BeforeAfterGrandBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 1); */
  /* background-color: #419fffbd; */
  ::-webkit-scrollbar {
    display: none;
  }
  /* background: linear-gradient(
    130deg,
    transparent 0%,
    transparent 10%,
    #419fff 40%,
    #419fff 100%
  ); */
`;

export const BeforeAfterGrand = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 1600px;
  max-width: 80%;
  padding: min(40px, 2vw) min(20px, 1vw);
  padding-top: 0;
  gap: 2%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
`;

export const TripleRowBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scroll-behavior: smooth;
  scroll-snap-align: center;
  width: 100%;
  flex-shrink: 0;
  box-shadow: 0 0 min(10px, 0.5vw) #09182e;
  overflow: hidden;
  border-radius: min(20px, 1vw);
  margin-top: min(20px, 1vw);
  margin-left: min(10px, 0.5vw);
  margin-right: min(10px, 0.5vw);
`;

export const TripleBoxCard = styled.div`
  position: relative;
  /* width: calc(33% - min(20px, 2vw)); */
  width: 50%;
  padding-top: 70%;
  /* aspect-ratio: 7 / 9; */
  /* border-radius: 3%; */
  background: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 min(10px, 0.5vw) black;
  /* filter: saturate(1.2) brightness(1.1); */
  /* &:first-child {
    margin-left: min(10px, 1vw);
  }
  &:last-child {
    margin-right: min(10px, 1vw);
  } */
`;

export const TripleBoxGradient = styled.div`
  position: absolute;
  width: 4%;
  height: 100%;
  left: 48%;
  background: linear-gradient(
    to left,
    transparent,
    transparent 20%,
    #8ec6ff 45%,
    #8ec6ff 55%,
    transparent 80%,
    transparent
  );
`;

export const CardTitle = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: min(20px, 1vw);
  width: 100%;
  font-weight: 600;
  /* aspect-ratio: 7 / 1; */
  top: 0;
  /* background-color: #2a78c7; */
  color: black;
  font-size: min(30px, 1.5vw);
`;

export const CardDesc = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: 600;
  /* aspect-ratio: 12 / 1; */
  bottom: 0;
  background-color: #ffffff;
  color: black;
  font-size: min(22px, 1.1vw);
  padding: min(20px, 1vw);
`;

export const ArrowRightAfter = styled.div`
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  aspect-ratio: 7 / 5;
  background: url(${arrow});
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg)
    brightness(102%) contrast(102%);
`;

export const ArrowRightBack = styled.div`
  position: absolute;
  left: 40%;
  width: 20%;
  aspect-ratio: 14 / 5;
  filter: invert(100%) sepia(95%) saturate(12%) hue-rotate(211deg)
    brightness(104%) contrast(100%);
  /* background: linear-gradient(
    to left,
    transparent,
    white 20%,
    white 80%,
    transparent
  ); */
`;

const arrowSlide = styled.div`
  position: absolute;
  width: 13%;
  height: 100%;
  /* aspect-ratio: 1 / 1; */
  background: url(${arrowSlideIcon});
  background-size: 120%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(23%) sepia(60%) saturate(1648%) hue-rotate(188deg)
    brightness(93%) contrast(91%);
  /* background-color: #ffffff; */
  border-radius: 100%;
  /* top: calc(43% - min(20px, 1vw)); */
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const arrowSlide2 = styled.div`
  flex-shrink: 0;
  width: 7%;
  margin-top: -1%;
  margin-bottom: -1%;
  aspect-ratio: 1 / 1;
  background: url(${arrowSlideIcon});
  background-size: 150%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(23%) sepia(60%) saturate(1648%) hue-rotate(188deg)
    brightness(93%) contrast(91%);
  /* background-color: #ffffff; */
  border-radius: 100%;
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
`;

export const ArrowRightSlide2 = styled(arrowSlide2)`
  transform: rotate(270deg);
`;

export const ArrowLeftSlide2 = styled(arrowSlide2)`
  transform: rotate(90deg);
  margin-top: 0;
`;

export const ArrowRightSlide = styled(arrowSlide)`
  right: -12%;
`;

export const ArrowLeftSlide = styled(arrowSlide)`
  left: -12%;
  transform: scale(-1);
`;

export const FakeBack = styled(BeforeAfterGrand)`
  position: absolute;
  height: 100%;
  overflow: visible;
`;

export const DotBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: min(10px, 0.5vw);
  aspect-ratio: 10 / 1;
  margin-bottom: min(60px, 3vw);
  margin-top: min(30px, 1.5vw);
`;

export const DotCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(24px, 2vw);
  height: min(24px, 2vw);
  border-radius: 24px;
  background-color: #5084c4;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
  transform: scale(${(props) => (props.selected ? 1.1 : 0.8)});
`;

export const DotCircleSmall = styled.div`
  width: 70%;
  height: 70%;
  border-radius: 24px;
  background-color: #448be2;
`;
