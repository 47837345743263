import {
  review1,
  review2,
  review3,
  review4,
  review5,
} from "../global/components/constants";
import { EducationGrand } from "../global/components/style/Education/style";
import {
  Block1Back,
  MainHeaderCanDo,
} from "../global/components/style/NewStyle";
import {
  ReviewBlockBig,
  ReviewIcon,
  ReviewName,
  ReviewRow,
  ReviewStar,
  ReviewStars,
  ReviewText,
  ReviewTopBigFlex,
  ReviewTopFlex,
} from "../global/components/style/Reviews/style";
import { isMobile } from "react-device-detect";

const starArr = new Array(5).fill();

const Reviews = () => {
  return (
    <Block1Back style={{ background: "#c9ebff" }}>
      {!isMobile ? (
        <EducationGrand
          style={{
            marginBottom: "min(50px, 2.5vw)",
            width: "2000px",
            maxWidth: "70%",
            // aspectRatio: "10 / 8",
            overflow: "hidden",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <MainHeaderCanDo>отзывы</MainHeaderCanDo>
          {/* <div style={{ width: "100%", position: "relative" }}> */}
          <ReviewBlockBig style={{ alignSelf: "flex-start" }}>
            <ReviewTopBigFlex>
              <ReviewIcon url={review4.img}></ReviewIcon>
              <ReviewName>{review4.name}</ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText>{review4.text}</ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-end" }}>
            <ReviewTopBigFlex>
              <ReviewIcon url={review5.img}></ReviewIcon>
              <ReviewName>{review5.name}</ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ "padding-bottom": "min(60px, 3vw)" }}>
              {review5.text}
            </ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-start" }}>
            <ReviewTopBigFlex>
              <ReviewIcon url={review3.img}></ReviewIcon>
              <ReviewName>{review3.name}</ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText>{review3.text}</ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-end" }}>
            <ReviewTopBigFlex>
              <ReviewIcon url={review2.img}></ReviewIcon>
              <ReviewName>{review2.name}</ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText>{review2.text}</ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-start" }}>
            <ReviewTopBigFlex>
              <ReviewIcon url={review1.img}></ReviewIcon>
              <ReviewName>{review1.name}</ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText>{review1.text}</ReviewText>
          </ReviewBlockBig>

          {/* </div> */}
        </EducationGrand>
      ) : (
        <EducationGrand
          style={{
            marginBottom: "min(50px, 2.5vw)",
            width: "2000px",
            maxWidth: "94%",
            // aspectRatio: "10 / 8",
            overflow: "hidden",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <MainHeaderCanDo style={{ fontSize: "min(70px, 3.5vw)" }}>
            отзывы
          </MainHeaderCanDo>
          {/* <div style={{ width: "100%", position: "relative" }}> */}
          <ReviewBlockBig style={{ alignSelf: "flex-start", width: "85%" }}>
            <ReviewTopBigFlex>
              <ReviewIcon
                url={review4.img}
                style={{ width: "min(140px, 7vw)", height: "min(140px, 7vw)" }}
              ></ReviewIcon>
              <ReviewName style={{ fontSize: "min(60px, 3vw)" }}>
                {review4.name}
              </ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ fontSize: "min(50px, 2.5vw)" }}>
              {review4.text}
            </ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-end", width: "85%" }}>
            <ReviewTopBigFlex>
              <ReviewIcon
                url={review5.img}
                style={{ width: "min(140px, 7vw)", height: "min(140px, 7vw)" }}
              ></ReviewIcon>
              <ReviewName style={{ fontSize: "min(60px, 3vw)" }}>
                {review5.name}
              </ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ fontSize: "min(50px, 2.5vw)" }}>
              {review5.text}
            </ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-start", width: "85%" }}>
            <ReviewTopBigFlex>
              <ReviewIcon
                url={review3.img}
                style={{ width: "min(140px, 7vw)", height: "min(140px, 7vw)" }}
              ></ReviewIcon>
              <ReviewName style={{ fontSize: "min(60px, 3vw)" }}>
                {review3.name}
              </ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ fontSize: "min(50px, 2.5vw)" }}>
              {review3.text}
            </ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-end", width: "85%" }}>
            <ReviewTopBigFlex>
              <ReviewIcon
                url={review2.img}
                style={{ width: "min(140px, 7vw)", height: "min(140px, 7vw)" }}
              ></ReviewIcon>
              <ReviewName style={{ fontSize: "min(60px, 3vw)" }}>
                {review2.name}
              </ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ fontSize: "min(50px, 2.5vw)" }}>
              {review2.text}
            </ReviewText>
          </ReviewBlockBig>
          <ReviewBlockBig style={{ alignSelf: "flex-start", width: "85%" }}>
            <ReviewTopBigFlex>
              <ReviewIcon
                url={review1.img}
                style={{ width: "min(140px, 7vw)", height: "min(140px, 7vw)" }}
              ></ReviewIcon>
              <ReviewName style={{ fontSize: "min(60px, 3vw)" }}>
                {review1.name}
              </ReviewName>
            </ReviewTopBigFlex>
            <ReviewStars>
              {starArr.map((_) => {
                return <ReviewStar></ReviewStar>;
              })}
            </ReviewStars>
            <ReviewText style={{ fontSize: "min(50px, 2.5vw)" }}>
              {review1.text}
            </ReviewText>
          </ReviewBlockBig>

          {/* </div> */}
        </EducationGrand>
      )}
    </Block1Back>
  );
};

export default Reviews;
