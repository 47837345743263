import { exp1, exp2 } from "../global/components/constants";
import { EducationGrand } from "../global/components/style/Education/style";
import {
  ExpRow,
  ExpTitle,
  ExpYear,
  TopExp,
  TopExpFlex,
  TopExpTitle,
  TopExpYear,
} from "../global/components/style/Exp/style";
import {
  Block1Back,
  MainHeaderCanDo,
} from "../global/components/style/NewStyle";
import { isMobile } from "react-device-detect";

const Exp = () => {
  return (
    <Block1Back style={{ background: "#c9ebff" }}>
      <EducationGrand
        style={{
          marginBottom: "3%",
          width: isMobile ? "100%" : "2000px",
          maxWidth: isMobile ? "" : "70%",
        }}
      >
        {isMobile ? (
          <MainHeaderCanDo style={{ fontSize: "min(70px, 3.5vw)" }}>
            опыт работы
          </MainHeaderCanDo>
        ) : (
          <MainHeaderCanDo>опыт работы</MainHeaderCanDo>
        )}
        {!isMobile ? (
          <TopExp>
            {exp1.map((el, i) => {
              return (
                <TopExpFlex>
                  <TopExpTitle
                    style={{
                      fontSize: "min(18px, 0.9vw)",
                      paddingLeft: "min(10px, 0.5vw)",
                    }}
                  >
                    {el.title}
                  </TopExpTitle>
                  <TopExpYear style={{ fontSize: "min(22px, 1.1vw)" }}>
                    {el.year}
                  </TopExpYear>
                </TopExpFlex>
              );
            })}
          </TopExp>
        ) : (
          <TopExp style={{ flexDirection: "column", alignItems: "center" }}>
            {exp1.map((el, i) => {
              return (
                <TopExpFlex style={{ border: "none" }}>
                  <TopExpTitle style={{ fontSize: "min(50px, 2.5vw)" }}>
                    {el.title}
                  </TopExpTitle>
                  <TopExpYear style={{ fontSize: "min(60px, 3vw)" }}>
                    {el.year}
                  </TopExpYear>
                </TopExpFlex>
              );
            })}
          </TopExp>
        )}
        {exp2.map((el, i) => {
          return !isMobile ? (
            <ExpRow>
              <ExpYear style={{ fontSize: "min(34px, 1.7vw)" }}>
                {el.year}
              </ExpYear>
              <ExpTitle style={{ fontSize: "min(22px, 1.1vw)" }}>
                {el.title}
              </ExpTitle>
            </ExpRow>
          ) : (
            <ExpRow
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <ExpYear
                style={{
                  fontSize: "min(80px, 4vw)",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {el.year}
              </ExpYear>
              <ExpTitle
                style={{
                  fontSize: "min(54px, 2.7vw)",
                  width: "100%",
                  whiteSpace: "pre-wrap",
                  padding: "min(40px, 2vw)",
                }}
              >
                {/* {"  "} */}
                {el.title}
              </ExpTitle>
            </ExpRow>
          );
        })}
      </EducationGrand>
    </Block1Back>
  );
};

export default Exp;
