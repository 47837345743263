import { useState } from "react";
import {
  aboutArr,
  aboutArrDescs,
  aboutGrad,
  aboutSize,
} from "../global/components/constants";
import {
  AboutBottom,
  AboutGrand,
  AboutGrandBox,
  AboutTitle,
  AboutTop,
} from "../global/components/style/About/style";
import { isMobile } from "react-device-detect";

const About = () => {
  const [selected, setSelected] = useState(0);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <AboutGrandBox>
      {isMobile ? (
        <AboutGrand>
          <AboutTop>
            {aboutArr.map((el, i) => {
              return (
                <AboutTitle
                  selected={selected === i}
                  onClick={() => setSelected(i)}
                  style={{
                    fontSize: "min(36px, 1.8vw)",
                    padding: "min(50px, 2.5vw) 0",
                  }}
                >
                  {el}
                </AboutTitle>
              );
            })}
          </AboutTop>
          <AboutBottom
            gradient={aboutGrad[selected]}
            style={{ fontSize: "min(60px, 3vw)", width: "94%" }}
          >
            {selected ? (
              aboutArrDescs[selected]
            ) : (
              <span>
                <span>В настоящее время работаю в </span>
                <span
                  style={{
                    color: "rgb(9 180 255)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    openInNewTab(
                      "https://www.cchp.ru/bolnitsa/centri-i-otdeleniya/terapevticheskaya-sluzhba/tsentr-podologii/"
                    )
                  }
                >
                  ФГБУ ЦКБ с поликлиникой Управления делами Президента РФ
                </span>
                <span>
                   и команде врачей спасения нижних конечностей при угрозе
                  ампутации 
                </span>
                <span
                  style={{
                    color: "rgb(9 180 255)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => openInNewTab("https://kink.su/")}
                >
                  КИНК.РФ
                </span>
              </span>
            )}
          </AboutBottom>
        </AboutGrand>
      ) : (
        <AboutGrand>
          <AboutTop>
            {aboutArr.map((el, i) => {
              return (
                <AboutTitle
                  selected={selected === i}
                  onClick={() => setSelected(i)}
                >
                  {el}
                </AboutTitle>
              );
            })}
          </AboutTop>
          <AboutBottom gradient={aboutGrad[selected]}>
            {selected ? (
              aboutArrDescs[selected]
            ) : (
              <span>
                <span>В настоящее время работаю в </span>
                <span
                  style={{
                    color: "rgb(9 180 255)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    openInNewTab(
                      "https://www.cchp.ru/bolnitsa/centri-i-otdeleniya/terapevticheskaya-sluzhba/tsentr-podologii/"
                    )
                  }
                >
                  ФГБУ ЦКБ с поликлиникой Управления делами Президента РФ
                </span>
                <span>
                   и команде врачей спасения нижних конечностей при угрозе
                  ампутации 
                </span>
                <span
                  style={{
                    color: "rgb(9 180 255)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => openInNewTab("https://kink.su/")}
                >
                  КИНК.РФ
                </span>
              </span>
            )}
          </AboutBottom>
        </AboutGrand>
      )}
    </AboutGrandBox>
  );
};

export default About;
