import { useEffect, useRef, useState } from "react";
import { after, baText, before } from "../global/components/constants";
import {
  ArrowLeftSlide,
  ArrowRightAfter,
  ArrowRightBack,
  ArrowRightSlide,
  BeforeAfterGrand,
  BeforeAfterGrandBox,
  CardDesc,
  CardTitle,
  DotBox,
  DotCircle,
  DotCircleSmall,
  FakeBack,
  TripleBoxCard,
  TripleBoxGradient,
  TripleRowBox,
} from "../global/components/style/Slider/style";
import { MainHeaderCanDo } from "../global/components/style/NewStyle";
import { isMobile } from "react-device-detect";
let step = 0;
let cS = 0;

const SliderBeforeAfter = () => {
  const sliderRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderClickHandler = (diff, type) => {
    if (sliderRef) {
      sliderRef.current.scrollLeft +=
        sliderRef?.current?.offsetWidth * (type ? -1 : 1) * diff;
    }
  };

  const sliderTimer = () => {
    const maxSteps = 337;

    setInterval(() => {
      step = (step + 1) % maxSteps;
      if (!step) {
        sliderClickHandler(cS + 1 === before.length ? -4 : 1);
        cS = (cS + 1) % before.length;
        setCurrentSlide((prev) => (prev + 1) % before.length);
      }
    }, 37);
  };

  useEffect(() => {
    sliderRef.current.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
      },
      { passive: false }
    );
    sliderTimer();
  }, []);

  return (
    <BeforeAfterGrandBox style={{ width: isMobile ? "100%" : "70%" }}>
      {isMobile ? (
        <MainHeaderCanDo style={{ fontSize: "min(70px, 3.5vw" }}>
          {"результаты работы"}
        </MainHeaderCanDo>
      ) : (
        <MainHeaderCanDo>{"результаты работы"}</MainHeaderCanDo>
      )}
      <FakeBack>
        <ArrowLeftSlide
          onClick={() => {
            step = 0;
            sliderClickHandler(!currentSlide ? -4 : 1, true);
            cS = !cS ? before.length - 1 : cS - 1;
            setCurrentSlide((prev) => (!prev ? before.length - 1 : prev - 1));
          }}
        ></ArrowLeftSlide>
        <ArrowRightSlide
          onClick={() => {
            step = 0;
            sliderClickHandler(currentSlide + 1 === before.length ? -4 : 1);
            cS = (cS + 1) % before.length;
            setCurrentSlide((prev) => (prev + 1) % before.length);
          }}
        ></ArrowRightSlide>
      </FakeBack>
      <BeforeAfterGrand
        ref={sliderRef}
        style={{ pointerEvents: "none", touchAction: "none" }}
      >
        {before.map((el, i) => {
          return (
            <TripleRowBox key={i}>
              <TripleBoxCard url={el}>
                {isMobile ? (
                  <CardTitle style={{ fontSize: "min(60px, 3vw)" }}>
                    {"ДО"}
                  </CardTitle>
                ) : (
                  <CardTitle>{"ДО"}</CardTitle>
                )}
              </TripleBoxCard>
              <TripleBoxCard url={after[i]}>
                {isMobile ? (
                  <CardTitle style={{ fontSize: "min(60px, 3vw)" }}>
                    {"ПОСЛЕ"}
                  </CardTitle>
                ) : (
                  <CardTitle>{"ПОСЛЕ"}</CardTitle>
                )}
              </TripleBoxCard>
              {/* <TripleBoxGradient></TripleBoxGradient> */}
              <ArrowRightBack
                style={{
                  top: 0,
                  // borderBottom: "1px solid #419fff",
                }}
              ></ArrowRightBack>
              {/* <ArrowRightAfter></ArrowRightAfter> */}
              <ArrowRightBack style={{ bottom: 0 }}></ArrowRightBack>
              {isMobile ? (
                <CardDesc style={{ fontSize: "min(40px, 2vw)" }}>
                  {baText[i]}
                </CardDesc>
              ) : (
                <CardDesc>{baText[i]}</CardDesc>
              )}
              {/* <TripleBoxCard></TripleBoxCard> */}
            </TripleRowBox>
          );
        })}
      </BeforeAfterGrand>
      <DotBox>
        {before.map((_, i) => {
          return !isMobile ? (
            <DotCircle
              key={i}
              selected={currentSlide === i}
              onClick={() => {
                step = 0;
                const diff = i - currentSlide;
                if (diff) {
                  sliderClickHandler(diff * (diff > 0 ? -1 : 1), diff > 0);
                  cS = i;
                  setCurrentSlide(i);
                }
              }}
            >
              <DotCircleSmall></DotCircleSmall>
            </DotCircle>
          ) : (
            <DotCircle
              key={i}
              selected={currentSlide === i}
              onClick={() => {
                step = 0;
                const diff = i - currentSlide;
                if (diff) {
                  sliderClickHandler(diff * (diff > 0 ? -1 : 1), diff > 0);
                  cS = i;
                  setCurrentSlide(i);
                }
              }}
              style={{ width: "min(48px, 4vw)", height: "min(48px, 4vw)" }}
            >
              <DotCircleSmall></DotCircleSmall>
            </DotCircle>
          );
        })}
      </DotBox>
    </BeforeAfterGrandBox>
  );
};

export default SliderBeforeAfter;
