import { keyframes, styled } from "styled-components";

const blow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const out = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const blow2 = keyframes`
  from {
    opacity: 0;
    width: 600px;
    max-width: 60%;
  }
  to {
    opacity: 1;
    width: 900px;
    max-width: 90%;
  }
`;

const out2 = keyframes`
  from {
    opacity: 1;
    width: 900px;
    max-width: 90%;
  }
  to {
    opacity: 0;
    width: 600px;
    max-width: 60%;
  }
`;

export const ModalBack = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  backdrop-filter: blur(5px) grayscale(0.2) brightness(0.3);
  animation: ${(props) => (props.blow ? blow : out)} 0.37s;
`;

export const ModalGrand = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  max-width: 90%;
  flex-shrink: 0;
  gap: min(40px, 2vw);
  /* height: min(600px, 30vw); */
  /* aspect-ratio: 8 / 3; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #22568a;
  border-radius: min(30px, 1.5vw);
  box-shadow: 0 0 min(20px, 1vw) black;
  z-index: 30;
  animation: ${(props) => (props.blow ? blow2 : out2)} 0.37s;
`;

export const ModalDesc = styled.div`
  width: 90%;
  text-align: center;
  font-size: min(120%, 2vw);
  color: white;
  margin-top: min(40px, 2vw);
`;

export const NameArea = styled.textarea`
  resize: none;
  width: 90%;
  /* aspect-ratio: 13 / 1; */
  border-radius: min(20px, 1vw);
  font-size: min(180%, 3vw);
  padding: min(14px, 0.7vw);
  font-weight: 600;
  outline-color: #419fff;
  &:focus {
    background-color: #dbedff;
    color: #2564a3;
  }
  overflow: hidden;
`;

export const PhoneArea = styled.textarea`
  resize: none;
  width: 90%;
  /* aspect-ratio: 13 / 1; */
  border-radius: min(20px, 1vw);
  font-size: min(180%, 3vw);
  padding: min(14px, 0.7vw);
  font-weight: 600;
  outline-color: #419fff;
  &:focus {
    background-color: #dbedff;
    color: #2564a3;
  }
  overflow: hidden;
`;

export const ModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: min(20px, 2vw);
  background-color: ${(props) => (props.enabled ? "#419fff" : "#606264")};
  border: min(4px, 0.2vw) solid #c5e6ff;
  color: white;
  border-radius: min(20px, 1vw);
  font-size: min(180%, 3vw);
  font-weight: 600;
  user-select: none;
  cursor: ${(props) => (props.enabled ? "pointer" : "")};
  opacity: ${(props) => (props.enabled ? 1 : 0.3)};
  &:hover {
    filter: ${(props) => (props.enabled ? "brightness(1.1)" : "")};
  }
  margin-bottom: min(40px, 2vw);
`;
