import { useEffect, useRef, useState } from "react";
// import { Blur, CloseBtn, ContactButton, Footer, FooterLeft, FooterRight, GlobalBlock, HeadInfo1, HeadInfo2, LeftSide, Logo, ModalContactBlock, Noga1, PartBlock, PhoneNumber, RightSide, SendBtn, SocialWeb, TextArea, UniBlock, WelcomeBlock } from "./global/components/style";
import close from "./global/components/img/close.svg";
import noga1 from "./global/components/img/noga1.jpg";
import logo from "./global/components/img/logo.jpg";
import photo from "./global/components/img/photo.png";
import bw1 from "./global/components/img/bw1.jpg";
import arrow from "./global/components/img/arrow_down_direction_navigation_download_icon_192499.svg";
import {
  Base,
  BigCanDo,
  Block1,
  Block1Back,
  Block2,
  Block3,
  Block4,
  CanDoBlock,
  CanDoDesc,
  Column,
  ContactButton,
  DescBox,
  Enroll,
  FooterNew,
  Gradien,
  Header,
  HeaderCanDo,
  KekW,
  LeftPartFooter,
  LeftPartHeader,
  Logo,
  MainHeaderCanDo,
  OpenArrow,
  OpenArrowBlock,
  PhoneBox,
  PhoneForm,
  PhoneIcon,
  PhoneNumber,
  PhoneNumberFlex,
  Photo,
  PhotoBlock,
  PriceCard,
  PriceCardFlex,
  PriceIcon,
  PriceLine,
  PricePart,
  PricePrice,
  PricePriceFlex,
  PriceTitle,
  RightPartFooter,
  RightPartHeader,
  ServicePart,
  TWBox,
  Telegram,
  TextMicroPart,
  TextPart,
  TitleBox,
  Trans,
  Transition,
  UpButton,
  UpImage,
  Whatsapp,
  YouTube,
} from "./global/components/style/NewStyle";
import {
  about,
  about1,
  about2,
  about3,
  canDo1,
  canDo2,
  canDo3,
  canDo4,
  headerCanDo1,
  headerCanDo2,
  headerCanDo3,
  headerCanDo4,
  price,
  title,
  whatDo,
} from "./global/components/constants";
import SliderForm from "./components/SliderForm";
import SliderBeforeAfter from "./components/SliderBeforeAfter";
import {
  BackgroundFlex,
  BackgroundOne,
  BackgroundThree,
  BackgroundTwo,
} from "./global/components/style/Background/style";
import {
  ArtifactBig,
  ArtifactMedium,
  ArtifactRow,
} from "./global/components/style/Artifacts/style";
import About from "./components/About";
import {
  ArrowLeftSlide2,
  ArrowRightSlide2,
} from "./global/components/style/Slider/style";
import Education from "./components/Education";
import Exp from "./components/Exp";
import Reviews from "./components/Reviews";
import Map from "./components/Map";
import Mapp from "./components/Map";
import Modal from "./components/Modal";
import { isMobile } from "react-device-detect";
const youtubelink = "https://youtube.com/@doctor_maximova?si=NXHsW5ZpKj2RWNNn";

let titlePos = 0;
let go = false;
let dir = 0;
let changeStatus = false;

function App() {
  const [enablebtn, SetEnablebtn] = useState(false);
  const [resize, SetResize] = useState(false);
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const [isArrowClicked2, setIsArrowClicked2] = useState(false);
  const [resize2, SetResize2] = useState(false);

  const [titleTmp, setTitleTmp] = useState(title.slice(0, 8));
  const [flexStatus, setFlexStatus] = useState(true);
  const [smooth, setSmooth] = useState(false);
  const [first, setFirst] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const [vh, setVh] = useState(window.innerHeight);

  const ref = useRef();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  // const [titlePos, setTitlePos] = useState(0);

  // const [arrowStatus, setArrowStatus] = useState("start");

  const priceRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setVh(window.innerHeight);
    });
    // priceRef.current.addEventListener(
    //   "wheel",
    //   (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     return false;
    //   },
    //   { passive: false }
    // );
  }, []);

  useEffect(() => {
    if (!first) {
      if (!dir) {
        priceRef.current.scrollTop = 10000;
        const step = Math.ceil(priceRef.current.offsetHeight / 37);
        const interval = setInterval(() => {
          priceRef.current.scrollTop -= step;
          if (priceRef.current.scrollTop <= 1) {
            clearInterval(interval);
            go = false;
          }
        }, 13);
      } else {
        priceRef.current.scrollTop = 0;
        const step = Math.ceil(priceRef.current.offsetHeight / 37);
        const interval = setInterval(() => {
          priceRef.current.scrollTop += step;
          if (
            priceRef.current.scrollTop >=
            priceRef.current.scrollHeight - priceRef.current.offsetHeight - 1
          ) {
            clearInterval(interval);
            changeStatus = true;
            const arrNew = [];
            for (let i = 4; i < titleTmp.length; i++) {
              arrNew.push(titleTmp[i]);
            }
            titlePos =
              (title.findIndex((el) => el.title === arrNew[3].title) + 1) %
              title.length;
            for (let i = 0; i < 4; i++) {
              arrNew.push(title[titlePos]);
              titlePos = (titlePos + 1) % title.length;
            }
            go = false;
            setTitleTmp(arrNew);
          }
        }, 13);
      }
    }
  }, [flexStatus]);

  // useEffect(() => {
  //   if (changeStatus) {
  //     changeStatus = false;
  //     go = false;
  //     priceRef.current.scrollTop = 0;
  //   }
  // }, [titleTmp]);

  return (
    <>
      {showModal && <Modal setShowModal={setShowModal} />}
      <KekW>
        {scrolled && (
          <UpButton
            onClick={() => {
              ref.current.scrollTop = 0;
            }}
          >
            <UpImage></UpImage>
          </UpButton>
        )}
        <Base
          ref={ref}
          onScroll={() => {
            setScrolled(ref.current.scrollTop > 100);
          }}
          vh={vh}
        >
          {/* <Header>
          <LeftPartHeader>
            <Logo logo={logo}></Logo>
          </LeftPartHeader>
          <RightPartHeader>
            <PhoneForm>Звоните по номеру +7 (777)777-77-77 или</PhoneForm>
            <ContactButton onClick={() => SetEnablebtn((prev) => !prev)}>
              {" "}
              Оставьте заявку
            </ContactButton>
          </RightPartHeader>
        </Header> */}
          <BackgroundFlex>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
            <BackgroundOne></BackgroundOne>
          </BackgroundFlex>
          {/* <Gradien></Gradien> */}
          <Block1Back>
            <Block1 isClicked={isArrowClicked} resize={resize}>
              {/* <ArtifactRow>
              <ArtifactBig></ArtifactBig>
            </ArtifactRow> */}
              <TextPart>
                {/* <TextMicroPart>{about1}</TextMicroPart>
              <TextMicroPart>{about2}</TextMicroPart>
              <TextMicroPart>{about3}</TextMicroPart> */}
                <TitleBox>{"Максимова Надежда Викторовна"}</TitleBox>
                <DescBox>
                  {
                    "Врач-эндокринолог, подолог (подиатр), кандидат медицинских наук"
                  }
                </DescBox>
                <Column style={{ bottom: isMobile ? "10%" : "7%" }}>
                  {isMobile ? (
                    <>
                      <PhoneBox style={{ width: "90%" }}>
                        <PhoneNumberFlex>
                          <PhoneIcon
                            style={{ width: "23%", height: "23%" }}
                          ></PhoneIcon>
                          <PhoneNumber style={{ fontSize: "min(56px, 2.8vw)" }}>
                            {"+7 (926) 697-85-43"}
                          </PhoneNumber>
                        </PhoneNumberFlex>
                        <Enroll
                          style={{ fontSize: "min(50px, 2.5vw)" }}
                          onClick={() => setShowModal(true)}
                        >
                          {"записаться"}
                        </Enroll>
                      </PhoneBox>
                    </>
                  ) : (
                    <>
                      <PhoneBox>
                        <PhoneNumberFlex>
                          <PhoneIcon></PhoneIcon>
                          <PhoneNumber>{"+7 (926) 697-85-43"}</PhoneNumber>
                        </PhoneNumberFlex>
                        <Enroll onClick={() => setShowModal(true)}>
                          {"записаться"}
                        </Enroll>
                      </PhoneBox>
                    </>
                  )}
                </Column>
              </TextPart>
              <PhotoBlock>
                <Photo photo={photo}></Photo>
                {/* <TextPart
              style={{
                width: "90%",
                "border-top": "2px solid #64b594",
                "padding-top": "0px",
              }}
            >
              {whatDo}
            </TextPart> */}
              </PhotoBlock>
            </Block1>
          </Block1Back>

          {/* <Transition></Transition> */}

          <About />

          {/* <Trans></Trans> */}

          <Block1Back style={{ background: "#c9ebff" }}>
            {!isMobile ? (
              <Block2 style={{ paddingBottom: "min(30px, 1.5vw)" }}>
                <MainHeaderCanDo>Чем я занимаюсь</MainHeaderCanDo>
                <BigCanDo>
                  <CanDoBlock>
                    <HeaderCanDo>{headerCanDo1}</HeaderCanDo>
                    <CanDoDesc>{canDo1}</CanDoDesc>
                  </CanDoBlock>
                  <CanDoBlock>
                    <HeaderCanDo>{headerCanDo2}</HeaderCanDo>
                    <CanDoDesc>{canDo2}</CanDoDesc>
                  </CanDoBlock>
                </BigCanDo>
                <BigCanDo>
                  <CanDoBlock>
                    <HeaderCanDo>{headerCanDo3}</HeaderCanDo>
                    <CanDoDesc>{canDo3}</CanDoDesc>
                  </CanDoBlock>
                  <CanDoBlock>
                    <HeaderCanDo>{headerCanDo4}</HeaderCanDo>
                    <CanDoDesc>{canDo4}</CanDoDesc>
                  </CanDoBlock>
                </BigCanDo>
              </Block2>
            ) : (
              <Block2 style={{ width: "2000px", maxWidth: "96%" }}>
                <MainHeaderCanDo style={{ fontSize: "min(70px, 3.5vw" }}>
                  Чем я занимаюсь
                </MainHeaderCanDo>
                <CanDoBlock style={{ width: "92%" }}>
                  <HeaderCanDo
                    style={{
                      fontSize: "min(54px, 2.7vw)",
                    }}
                  >
                    {headerCanDo1}
                  </HeaderCanDo>
                  <CanDoDesc
                    style={{
                      fontSize: "min(50px, 2.5vw)",
                      height: "min(500px, 25vw)",
                    }}
                  >
                    {canDo1}
                  </CanDoDesc>
                </CanDoBlock>
                <CanDoBlock style={{ width: "92%", marginTop: "3%" }}>
                  <HeaderCanDo style={{ fontSize: "min(54px, 2.7vw)" }}>
                    {headerCanDo2}
                  </HeaderCanDo>
                  <CanDoDesc
                    style={{
                      fontSize: "min(50px, 2.5vw)",
                      height: "min(500px, 25vw)",
                    }}
                  >
                    {canDo2}
                  </CanDoDesc>
                </CanDoBlock>
                <CanDoBlock style={{ width: "92%", marginTop: "3%" }}>
                  <HeaderCanDo style={{ fontSize: "min(54px, 2.7vw)" }}>
                    {headerCanDo3}
                  </HeaderCanDo>
                  <CanDoDesc
                    style={{
                      fontSize: "min(50px, 2.5vw)",
                      height: "min(500px, 25vw)",
                    }}
                  >
                    {canDo3}
                  </CanDoDesc>
                </CanDoBlock>
                <CanDoBlock
                  style={{ width: "92%", marginTop: "3%", marginBottom: "3%" }}
                >
                  <HeaderCanDo style={{ fontSize: "min(54px, 2.7vw)" }}>
                    {headerCanDo4}
                  </HeaderCanDo>
                  <CanDoDesc
                    style={{
                      fontSize: "min(50px, 2.5vw)",
                      height: "min(500px, 25vw)",
                    }}
                  >
                    {canDo4}
                  </CanDoDesc>
                </CanDoBlock>
              </Block2>
            )}
          </Block1Back>

          {/* <Trans></Trans> */}

          <Block1Back style={{ background: "white" }}>
            <SliderBeforeAfter />
          </Block1Back>

          {/* <Trans></Trans> */}
          <Reviews />

          {/* <Trans></Trans> */}
          <Block1Back style={{ background: "white" }}>
            <Education />
          </Block1Back>

          {/* <Trans></Trans> */}

          <Exp />

          {/* <OpenArrowBlock
          onClick={() => {
            setIsArrowClicked(true);
            SetResize((prev) => !prev);
          }}
        >
          <OpenArrow arrow={arrow}></OpenArrow>
        </OpenArrowBlock> */}

          <Block1Back style={{ background: "transparent" }}></Block1Back>

          {/* <Block1Back style={{ background: "rgba(255, 255, 255, 0.85)" }}>
            <Block3 isClicked2={isArrowClicked2} resize2={resize2}>
              <MainHeaderCanDo
                style={{ color: "#515151" }}
              >{`Прайс`}</MainHeaderCanDo>
              <ArrowRightSlide2
                onClick={() => {
                  if (!go) {
                    if (dir) {
                      for (let i = 0; i < 8; i++) {
                        if (titlePos === 0) {
                          titlePos = title.length - 1;
                        } else {
                          titlePos -= 1;
                        }
                      }
                    }
                    dir = 0;
                    go = true;
                    const arr = [];
                    for (let i = 0; i < 4; i++) {
                      if (titlePos === 0) {
                        titlePos = title.length - 1;
                      } else {
                        titlePos -= 1;
                      }
                      arr.push(titlePos);
                    }
                    arr.reverse();
                    const arrNew = [];
                    for (let i = 0; i < arr.length; i++) {
                      arrNew.push(title[arr[i]]);
                    }
                    for (let i = 0; i < titleTmp.length - 4; i++) {
                      arrNew.push(titleTmp[i]);
                    }
                    setTitleTmp(arrNew);
                    setFirst(false);
                    setFlexStatus(!flexStatus);
                  }
                }}
              ></ArrowRightSlide2>
              <PriceCardFlex ref={priceRef}>
                {titleTmp.map((el, i) => (
                  // <PriceCard>
                  //   <PriceTitle>{el.title}</PriceTitle>
                  //   <PricePriceFlex>
                  //     <PricePrice>{price[i].price}</PricePrice>
                  //     <PriceIcon></PriceIcon>
                  //   </PricePriceFlex>
                  // </PriceCard>
                  <PriceLine>
                    <ServicePart>{el.title}</ServicePart>
                    <PricePart>{el.price}</PricePart>
                  </PriceLine>
                ))}
              </PriceCardFlex>
              <ArrowLeftSlide2
                onClick={() => {
                  if (!go) {
                    dir = 1;
                    go = true;
                    setFirst(false);
                    setFlexStatus(!flexStatus);
                    // setTitleTmp(arrNew);
                  }
                }}
              ></ArrowLeftSlide2>
            </Block3>
          </Block1Back> */}
          {/* <OpenArrowBlock
          onClick={() => {
            setIsArrowClicked2(true);
            SetResize2((prev) => !prev);
          }}
        >
          <OpenArrow arrow={arrow}></OpenArrow>
        </OpenArrowBlock> */}
          {/* <Block4>feedback</Block4> */}

          {/* <SliderForm /> */}

          <Mapp />

          {/* <FooterNew>
          <LeftPartFooter>123</LeftPartFooter>
          <RightPartFooter>321</RightPartFooter>
        </FooterNew> */}
        </Base>
      </KekW>
    </>
  );
}

export default App;
